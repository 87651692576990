@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@font-face {
  font-family: 'PPNeueMonetreal';
  src: url('../public/PPNeueMontreal-Regular.ttf') format('truetype');
}

body {
  background-color: white;
  font-family: 'figtree', sans-serif;
  width: 100%;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
  font-family: 'PPNeueMonetreal';
  color: black;
}

p, h1, h2, h3, h4, body {
  font-family: 'PPNeueMonetreal';
  color: black;
}

.whiteboardView .button-initial {
  background-color: white; /* White background */
  color: black;           /* Black text */
  border-radius: 10px;  
  font-weight: 400; 
  font-size: 12; 
  padding-left: 11vh;
  padding-right: 11vh;
  text-align: center;
  text-transform: none /* Rounded corners, adjust as needed */
}

.whiteboardView .button-active {
  background-color: #66C097; /* Green background */
  color: #fff;    
  font-weight: 400; 
  font-size: 12; 
  padding-left: 10vh;
  padding-right: 10vh;        /* Black text */
  border-radius: 10px;  
  text-transform: none /* Rounded corners, adjust as needed */    /* Rounded corners, adjust as needed */
}

.whiteboardView .button-active-mute {
background-color: #66C097; /* Green background */
color: #fff;    
font-weight: 400; 
font-size: 12; 
padding-left: 10vh;
padding-right: 10vh;        /* Black text */
border-radius: 10px;  
margin-left: 3vh;
text-transform: none /* Rounded corners, adjust as needed */    /* Rounded corners, adjust as needed */
}

.whiteboardView .button-error {
background-color: #FF0000; /* Error red background */
color: #fff;    
font-weight: 400;
margin-left: 3vh; 
font-size: 12; 
padding-left: 10vh;
padding-right: 10vh;        /* Black text */
border-radius: 10px;  
text-transform: none /* Rounded corners, adjust as needed */    /* Rounded corners, adjust as needed */
}

.whiteboardView .syncWhiteboard {
  background-color: black; /* White background */
  color: white;          /* Black text */
  border-radius: 8px; 
  border-color: #fff;
  font-weight: 400; 
  font-size: 12; 
  padding-left: 10vh;
  padding-right: 10vh;  
  margin-top: 1px;      /* Black text */
  border-radius: 10px;  
  text-transform: none /* Rounded corners, adjust as needed */
}


.whiteboardView .button-waitlist {
background-color: black; /* White background */
color: white;           /* Black text */
border-radius: 10px;  
font-weight: 400; 
font-size: 12; 
padding-left: 12vh;
padding-right: 12vh;
text-align: center;
text-transform: none; /* Rounded corners, adjust as needed */
border: 1px solid #fff;
margin-left: 3vh; 
}

.whiteboardView .button-sync {
background-color: white; /* White background */
color: black;           /* Black text */
border-radius: 10px;  
font-weight: 400; 
font-size: 12; 
padding-left: 12vh;
padding-right: 12vh;
text-align: center;
text-transform: none; /* Rounded corners, adjust as needed */
border: 1px solid #fff;
margin-left: 3vh; 
}

.headerModified {
color:#fff;
text-align: left;
padding-left: 30px; 
font-weight: 400;
}


.custom-excalidraw .excalidraw {
--color-primary: #fcc6d9;
--color-primary-darker: #f783ac;
--color-primary-darkest: #e64980;
--color-primary-light: #f2a9c4;
border-radius: 10px;
border: 1px solid #E9E9E9;
}

#chat::-webkit-scrollbar {
width: 10px;
background: #1E1E1E;
}

#chat::-webkit-scrollbar-track {
background: #1E1E1E; 
}

#chat::-webkit-scrollbar-thumb {
background: #888; 
}

#chat::-webkit-scrollbar-thumb:hover {
background: #555; 
}

/* Testing Excalidraw override  .Excalidraw {
  --color-primary: blue!important;
  --color-primary-darker: blue !important;
  --color-primary-darkest:blue !important;
  --color-primary-light: blue !important;
  --default-bg-color: blue !important;
  min-height: 60vh;
  border: 5px solid yellow; 
} */

.space-mono-regular {
font-family: "Mulish", monospace;
font-weight: 400;
font-style: normal;
}

.space-mono-regular-italic {
font-family: "Mulish", monospace;
font-weight: 400;
font-style: italic;
}

.space-mono-bold { 
font-family: "Mulish", monospace;
font-weight: 700;
font-style: normal;
}

.space-mono-bold-italic {
font-family: "Mulish", monospace;
font-weight: 700;
font-style: italic;
}

body {
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.btn {
cursor: pointer;
background: #20C58A;
padding: 10px;
border-radius: 6px;
color: white;
border: none;
font-weight: 600;
font-family: 'PPNeueMonetreal';
}

.btn:hover {
background-color: #17A974;
}

.btn:disabled {
background-color: #CCCCCC;
cursor: not-allowed;
}

.btn:active {
background-color: #128C5E;
}